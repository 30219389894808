import Banner from "./components/Banner";
import General from "./components/General";
import Project from "./components/Project";
import Orders from "../orders";
import Gifts from "../gifts";
import { toast } from "react-toastify";

import { useMutationProfessional, useQueryProfessional } from "graphQl/hooks";
import { useParams } from "react-router-dom";

const ProfessionalProfile = () => {
  const params = useParams();
  const { professional, loading, refetch, error } = useQueryProfessional(
    params?.id
  );
  const { setJustificationDateHandler } = useMutationProfessional();
  const changeJusDate = (justificationDate) => {
    setJustificationDateHandler({
      id: professional?.id,
      justificationDate,
    }).then((httpResponse) => {
      if (!httpResponse?.errors) {
        toast.success(
          "Date de validité de la Pièce d'identité a été modifié avec succès"
        );
        refetch();
      }
    });
  };
  return (
    <div className="flex w-full flex-col gap-5">
      <div className="w-ful mt-3 flex h-fit flex-col gap-5 lg:grid lg:grid-cols-12">
        <div className="col-span-4 lg:!mb-0">
          <Banner professional={professional} />
        </div>

        <div className="col-span-8 lg:col-span-8 lg:mb-0 3xl:col-span-8">
          <General changeJusDate={changeJusDate} professional={professional} />
        </div>
      </div>
      {/* all project & ... */}

      <div className="grid h-full grid-cols-1 gap-5 lg:!grid-cols-12">
        <div className="col-span-12 lg:col-span-12 lg:mb-0 3xl:col-span-12">
          <Project professional={professional} />
        </div>
      </div>
      <div className="grid h-full grid-cols-1 gap-5 lg:!grid-cols-12">
        <div className="col-span-12 lg:col-span-12 lg:mb-0 3xl:col-span-12">
          <Orders professional={professional?.id} />
        </div>
      </div>
      <div className="grid h-full grid-cols-1 gap-5 lg:!grid-cols-12">
        <div className="col-span-12 lg:col-span-12 lg:mb-0 3xl:col-span-12">
          <Gifts professional={professional?.id} />
        </div>
      </div>
    </div>
  );
};

export default ProfessionalProfile;
