import { useState,useEffect } from "react";
import Card from "components/card";
import ImageZoom from "components/imageZoom";
import React from "react";
import { professionalState } from "variables/constants";
import InputField from "components/fields/InputField";

import moment from "moment";
import "moment/locale/fr"; // without this line it didn't work
moment.locale("fr");

const General = ({ professional, changeJusDate }) => {
  const [justificationDate, setJustificationDate] = useState(
    0
  );

  const onChange = ({ target: { name, value } }) => {
    if (name === "justificationDate") setJustificationDate(value);
  };
  useEffect(() => {
    if(professional?.profile?.justificationDate)
    setJustificationDate(moment(professional?.profile?.justificationDate).format("YYYY-MM-DD"))
},[professional])
  return (
    <Card extra={"w-full h-full p-3"}>
      {/* Header */}
      <div className="mt-2 mb-8 w-full">
        <p className="text-black-800 mt-2 px-2 text-base">
          {professional?.profile?.bio}
        </p>
      </div>
      {/* Cards */}
      <div className="grid grid-cols-2 gap-4 px-2">
        <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:bg-navy-800 dark:shadow-none">
          <p className="text-black-800 text-md">Téléphone</p>
          <p className="text-sm text-[#4263c9] dark:text-white">
            {professional?.mobile}
          </p>
        </div>

        <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:bg-navy-800 dark:shadow-none">
          <p className="text-black-800 text-md">Adresse</p>
          <p className="text-sm text-[#4263c9] dark:text-white">
            {professional?.profile?.address}
          </p>
        </div>

        <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:bg-navy-800 dark:shadow-none">
          <p className="text-black-800 text-md">Statut professional</p>
          <p className="text-sm text-[#4263c9] dark:text-white">
            {professionalState[professional?.profile?.professionalState]}
          </p>
        </div>

        <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:bg-navy-800 dark:shadow-none">
          <p className="text-black-800 text-md">Fonction</p>
          <p className="text-sm text-[#4263c9] dark:text-white">
            {professional?.profile?.function}
          </p>
        </div>
        <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:bg-navy-800 dark:shadow-none">
          <p className="text-black-800 text-md">Date de naissance</p>
          <p className="text-sm text-[#4263c9] dark:text-white">
            {moment(professional?.profile?.birthday).format("DD/MM/YYYY")}
          </p>
        </div>
        <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:bg-navy-800 dark:shadow-none">
          <InputField
            variant="auth"
            extra="text-black-800 text-md"
            value={justificationDate}
            label="Date de validité de la Pièce d'identité*"
            placeholder="12/12/22"
            id="justificationDate"
            name="justificationDate"
            type="date"
            onChange={onChange}
          />
          {justificationDate !==
            moment(professional?.profile?.justificationDate).format(
              "YYYY-MM-DD"
            ) && (
            <button
              onClick={() =>
                changeJusDate(moment(justificationDate, "YYYY-MM-DD").valueOf())
              }
              className="linear mt-2 w-full rounded-xl bg-[#094cbb] py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
            >
              Modifier
            </button>
          )}
        </div>
        {professional?.profile?.photoUrl && (
          <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:bg-navy-800 dark:shadow-none">
            <p className="text-md text-black-800">Photo de profil</p>
            <p className="mt-2 text-sm text-[#4263c9] dark:text-white">
              <ImageZoom
                url={professional?.profile?.photoUrl}
                title="Photo de profil"
              />
            </p>
          </div>
        )}
        {professional?.profile?.justification && (
          <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:bg-navy-800 dark:shadow-none">
            <p className="text-md text-black-800">Pièce d'identité</p>
            <p className="mt-2 text-sm text-[#4263c9] dark:text-white">
              <ImageZoom
                url={professional?.profile?.justification}
                title="Pièce d'identité"
              />
            </p>
          </div>
        )}
      </div>
    </Card>
  );
};

export default General;
